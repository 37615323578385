import { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "./components/shared/footer";
import articles from "./json/articles.json";
const HomePage = lazy(() => import("./pages/home/home"));

function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={"Loading...."}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          {articles.map((item, i) => {
            const Page = lazy(() =>
              import(`./pages/articles${item.articlePath}`)
            );
            return (
              <Route path={item.slug} element={<Page articleInfo={item} />} />
            );
          })}
        </Routes>
      </Suspense>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
