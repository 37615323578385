import React from "react";

function Footer() {
  return (
    <div className="flex justify-between items-center bg-neutral-950 py-2 px-10 mt-5 text-slate-300">
      <div>
        <p className="text-sm" >Copyright © 2024 Mohammed Asiq. All rights reserved.</p>
      </div>
      <div>
        <p  className="text-sm">
          Author : <a href="https://mohammedasiq.com/" target="blank" className="text-yellow-500 underline underline-offset-4 ml-2" >Mohammed Asiq</a>
        </p>
      </div>
    </div>
  );
}

export default Footer;
